import { FormControlLabel, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImageWithClick } from 'components/icons/YellowCrownImage';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { FieldCheckbox } from 'features/workflowBlogPostBuilder/steps/FieldCheckbox';
import {
  CheckboxGroup,
  ProLabelContainer
} from 'features/workflowBlogPostBuilder/steps/Step3';

type Props = {
  featureDisabled?: boolean;
  seoDisabled?: boolean;
  isFreePlan: boolean;
};

export function Step3SeoSection({ featureDisabled, seoDisabled, isFreePlan }: Props) {
  const disabled = featureDisabled || seoDisabled;

  return (
    <section>
      <FlexContainer gap="small" direction="row">
        <Typography variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.elements.seo" />
        </Typography>
      </FlexContainer>
      <CheckboxGroup>
        <FormControlLabel
          control={<FieldCheckbox fieldName="elements" value="seobility" disabled={disabled} />}
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.seo_check" />
              </Typography>
              {isFreePlan && <YellowCrownImageWithClick variant="h6" />}
            </ProLabelContainer>
          }
        />
        <FormControlLabel
          control={
            <FieldCheckbox fieldName="elements" value="what_people_asked" disabled={disabled} />
          }
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.references.others_check" />
              </Typography>
              {isFreePlan && <YellowCrownImageWithClick variant="h6" />}
            </ProLabelContainer>
          }
        />
      </CheckboxGroup>
    </section>
  );
}
