import { IconButton, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import {
  ImageQuality,
  imageQualityOptions,
  useAiImagesGeneratorContext
} from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { ComponentProps, useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  SelectProps?: ComponentProps<typeof Select<ImageQuality>>;
  onQualityChange?: (value: ImageQuality) => void;
};

export const imageQualitySelectOptions: Record<string, ImageQuality> = {
  best_for_text: 'best_for_text',
  best: 'best',
  default: 'default'
};

export const imageQualityOptionsWithResolution: Array<ImageQuality> = [
  imageQualitySelectOptions.default,
  imageQualitySelectOptions.best,
  imageQualitySelectOptions.best_for_text
];

export const UpgradeButton = ({ onClick }: { onClick?: () => void }) => {

  return (
    <IconButton onClick={onClick}>
      <YellowCrownImage />
    </IconButton>
  );
}

export const ImageQualitySelect = ({ SelectProps, onQualityChange }: Props) => {
  const canSelectBestImageQuality = useAppSelector(getGetIsFeatureAvailable)('bestImageQuality');
  const canSelectBestForTextImageQuality = useAppSelector(getGetIsFeatureAvailable)('bestForTextImageQuality');

  const { imageQuality, setImageQuality, resolution, setResolution } =
    useAiImagesGeneratorContext();
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleSelectClose = () => {
    setIsSelectOpen(false);
  };

  const handleSelectOpen = () => {
    setIsSelectOpen(true);
  };

  const handleUpgradeClick = () => {
    showUpgradeSubscriptionModal();

    setIsSelectOpen(false);
  };

  const handleImageQualityChange = (event: SelectChangeEvent<ImageQuality>) => {
    const newQuality = event.target.value as ImageQuality;

    // This blocks "Upgrade" option from being selected
    if (!imageQualityOptions.includes(newQuality)) {
      return;
    }

    if (newQuality === imageQualitySelectOptions.best && !canSelectBestImageQuality) {
      return;
    }

    if (newQuality === imageQualitySelectOptions.best_for_text && !canSelectBestForTextImageQuality) {
      return;
    }

    setImageQuality(newQuality);
    setIsSelectOpen(false);

    if (!imageQualityOptionsWithResolution.includes(newQuality) && resolution !== '1:1') {
      setResolution('1:1');
    }

    onQualityChange?.(newQuality);
  };

  const handleBestForTextDisabledClick = () => {
    if (canSelectBestForTextImageQuality) {
      return;
    }

    handleUpgradeClick();
  }

  const handleBestDisabledClick = () => {
    if (canSelectBestImageQuality) {
      return;
    }

    handleUpgradeClick();
  }

  return (
    <QualitySelect
      open={isSelectOpen}
      onClose={handleSelectClose}
      onOpen={handleSelectOpen}
      value={imageQuality}
      onChange={handleImageQualityChange}
      label={<FormattedMessage id="ai_images.generator_page.quality.label" />}
      {...withGtmInteraction(gtmIds.aiImages.generatorPage.qualitySelect)}
      {...SelectProps}
    >
      <MenuItem value={imageQualitySelectOptions.default}>
        <FormattedMessage id="ai_images.generator_page.quality.default" />
      </MenuItem>

      <PotentialDisabledMenuItem
        value={imageQualitySelectOptions.best_for_text}
        onClick={handleBestForTextDisabledClick}
      >
        <PotentialDisabledMenuItemText>
          <FormattedMessage id="ai_images.generator_page.quality.best_for_text" />
        </PotentialDisabledMenuItemText>

        {!canSelectBestForTextImageQuality && (
          <UpgradeButton onClick={handleUpgradeClick} />
        )}
      </PotentialDisabledMenuItem>

      <PotentialDisabledMenuItem
        value={imageQualitySelectOptions.best}
        onClick={handleBestDisabledClick}
      >
        <PotentialDisabledMenuItemText>
          <FormattedMessage id="ai_images.generator_page.quality.best" />
        </PotentialDisabledMenuItemText>

        {!canSelectBestImageQuality && (
          <UpgradeButton onClick={handleUpgradeClick} />
        )}
      </PotentialDisabledMenuItem>
    </QualitySelect>
  );
};

const QualitySelect = styled(Select)`
  min-width: 120px;
` as typeof Select;

export const PotentialDisabledMenuItem = styled(MenuItem)`
  // MUI by default changes the opacity for disabled items, which is also applied to children
  // But we wanna show a upgrade chip, so we need to restore the opacity
  &&.Mui-disabled {
    opacity: 1;

    // For whatever reason changing the opacity re-enables changing the background-color
    // Therefore we need to unset it to no suggest the item can be selected
    &:hover {
      background-color: unset;
    }
  }
  display: flex;
  justify-content: space-between;
`;

export const PotentialDisabledMenuItemText = styled(Typography) <{ $isDisabled?: boolean }>`
  // As we removed the opacity reducing in disabled state, we simply change the text color
  color: ${({ theme, $isDisabled }) => ($isDisabled ? theme.colors.textDisabled : theme.colors.textPrimary)};
`;
