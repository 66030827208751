import styled from 'styled-components';

const getThumbnailUrl = (thumbnail: string | null | undefined) => {
  if (!thumbnail || thumbnail.length === 0) {
    return null;
  }

  // After adding a new file, it's a base64 data URL
  if (thumbnail.startsWith('data:image')) {
    return thumbnail;
  }

  // If it's an existing file, it's an absolute URL
  return thumbnail;
};

type Props = {
  thumbnail?: string | null;
};

export const ModularWorkflowThumbnailBox = ({ thumbnail }: Props) => {
  const imageUrl = getThumbnailUrl(thumbnail);

  return <Root>{imageUrl && <img src={imageUrl} alt="Workflow image" />}</Root>;
};

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  border-radius: ${({ theme }) => theme.borderRadius.two};
  border: 1px solid ${({ theme }) => theme.colors.divider};
  overflow: hidden;
`;
