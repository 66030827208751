import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Link as MaterialLink, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { FAQSection } from 'components/profile/components/pricing-plan-card/FAQSection';
import NotificationHint from 'components/profile/components/pricing-plan-card/NotificationHint';
import { SocialProof } from 'components/profile/components/pricing-plan-card/SocialProof';
import { routesConfig } from 'config/routes';
import { getCustomerRole } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { TrialCanceledBanner } from 'features/pricing/TrialCanceledBanner';
import { TrialExpiryBanner } from 'features/pricing/TrialExpiryBanner';
import { PricingPlan } from 'features/pricing/types';
import { ProfileInformationCard } from 'features/profilePage/ProfileInformationCard';
import { SubscriptionCard } from 'features/profilePage/SubscriptionCard';
import UpgradePlanCard from 'features/profilePage/UpgradePlanCard';
import { UsageCard } from 'features/profilePage/wordsUsage/UsageCard';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { InviteFriendsCard } from 'pages/bonusReferral/InviteFriendsCard';
import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { defaultTextColor } from 'styles/styleUtils';
import useTr from 'utils/hooks/useTr';

const profileInfoRoute = routesConfig.profileInfo.path;
const profileDashboardRoute = routesConfig.profileDashboard.path;
const profileBillingRoute = routesConfig.profileBilling.path;

const ProfilePage = (): ReactElement => {
  const { pathname } = useLocation();
  const translate = useTr();

  const currentPricingPlan = useAppSelector(getPricingPlan);
  const { group } = useAppSelector(state => state.group, shallowEqual);
  const { email, username, timezone, image } = useAppSelector(
    state => state.customer,
    shallowEqual
  );
  const userRole = useAppSelector(getCustomerRole);

  const isNotElligbleForUsersFeature =
    currentPricingPlan === PricingPlan.standard ||
    currentPricingPlan === PricingPlan.free ||
    currentPricingPlan === PricingPlan.basic;

  const [showDisclaimer, setShowDisclaimer] = useState(isNotElligbleForUsersFeature);
  const [currentRoute, setCurrentRoute] = useState(profileInfoRoute);

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  useEnableSidebarOnMount();

  useNavigateToOnboarding();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setCurrentRoute(newValue);
  };

  const closeDisclaimer = () => setShowDisclaimer(false);

  useEffect(() => {
    if (pathname !== currentRoute) {
      setCurrentRoute(pathname);
    }
  }, [pathname, currentRoute]);

  return (
    <Root>
      {showDisclaimer && (
        <NotificationHint>
          <FlexContainer
            fullWidth
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <StyledTypography variant="body2">
              <FormattedMessage
                id="profile.users.disclaimer"
                values={{
                  a: (msg: string) => (
                    <StyledLink
                      href={translate('external_links.app_landingpage_pricing_plans')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {msg}
                    </StyledLink>
                  ),
                  c: (content: string) => (
                    <MaterialLink
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: '#444444',
                        '&:hover': { color: '#444444' }
                      }}
                      onClick={() => showUpgradeSubscriptionModal()}
                    >
                      {content}
                    </MaterialLink>
                  )
                }}
              />
            </StyledTypography>
            <DeleteIconButton onClick={closeDisclaimer} color="textContrast" />
          </FlexContainer>
        </NotificationHint>
      )}
      <Box>
        <TabContext value={currentRoute}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={currentRoute}
              onChange={handleChange}
              aria-label={translate('profile.navigation')}
            >
              <Tab
                label={translate('profile.account.label')}
                component={Link}
                to={profileInfoRoute}
                value={profileInfoRoute}
                css={`
                  text-transform: none;
                `}
              />
              <Tab
                label={translate('profile.dashboard.label')}
                component={Link}
                to={profileDashboardRoute}
                value={profileDashboardRoute}
                css={`
                  text-transform: none;
                `}
              />
              {userRole === CustomerRole.OWNER && (
                <Tab
                  label={translate('profile.billing.label')}
                  component={Link}
                  to={profileBillingRoute}
                  value={profileBillingRoute}
                  LinkComponent={Link}
                  css={`
                    text-transform: none;
                  `}
                />
              )}
            </Tabs>
          </Box>
          <StyledTabPanel value={profileInfoRoute}>
            <>
              <ProfileInformationCard
                username={username}
                email={email}
                timezone={timezone}
                groupName={group?.name ?? '-'}
                avatarImage={image ?? undefined}
              />
              <InviteFriendsCard isHighlighted={false} />
            </>
          </StyledTabPanel>
          <StyledTabPanel value={profileDashboardRoute}>
            <UsageCard />
          </StyledTabPanel>
          <StyledTabPanel value={profileBillingRoute}>
            <BannersBox>
              <TrialExpiryBanner />
              <TrialCanceledBanner />
            </BannersBox>
            <UpgradePlanCard />
            <SubscriptionCard />
            <SocialProof />
            <FAQSection />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </Root>
  );
};

const Root = styled(Container).attrs({ size: 'lg' })`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;

const BannersBox = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;

const StyledLink = styled.a`
  color: ${defaultTextColor};
  text-decoration: underline;

  &:hover {
    color: inherit;
  }
`;

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.textContrast};
`;

const StyledTabPanel = styled(TabPanel)`
  padding-inline: 0;
`;

export default ProfilePage;
