import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

export const usePersonalityAuthor = () => {
  const customerId = useAppSelector(state => state.customer.id);
  const translate = useTr();

  return (personality: PersonalityDto) => {
    if (personality.customer_id === customerId) {
      return translate(
        'aiWriter.inspirations.chat.personalities.library_modal.personality.author_you'
      );
    }

    if (personality.sharing_permission === 'team' && personality.source) {
      return personality.source;
    }

    return translate(
      'aiWriter.inspirations.chat.personalities.library_modal.personality.author_system'
    );
  };
};
