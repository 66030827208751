import { Alert, AlertTitle } from '@mui/material';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { SessionStorageKey } from 'config/sessionStorageKey';
import { useGetTimeUntilTrialExpiration } from 'features/pricing/hook/useGetTimeUntilTrialExpiration';
import { useIsTrialCanceled } from 'features/pricing/hook/useIsTrialCanceled';
import { FormattedMessage } from 'react-intl';
import { useSessionStorage } from 'utils/hooks/useSessionStorage';

export const TrialCanceledBanner = () => {
  const { daysLeft, hoursLeft } = useGetTimeUntilTrialExpiration();
  const [shouldBeShown, setShouldBeShown] = useSessionStorage(
    SessionStorageKey.TrialCanceledBannerDismissed,
    true
  );

  const isTrialCanceled = useIsTrialCanceled();

  const handleDismissBanner = () => {
    sessionStorage.setItem(SessionStorageKey.TrialCanceledBannerDismissed, 'true');
    setShouldBeShown(false);
  };

  if (!isTrialCanceled || !shouldBeShown) {
    return null;
  }

  return (
    <Alert
      severity="info"
      variant="filled"
      action={<DeleteIconButton onClick={handleDismissBanner} />}
    >
      <AlertTitle>
        <FormattedMessage
          id="trial_canceled.banner.title"
          values={{ days: daysLeft, hours: hoursLeft }}
        />
      </AlertTitle>
    </Alert>
  );
};
