import { useImageGenerator } from 'features/aiImages/AiImagesPage/useImageGenerator';
import { usePromptOptimizer } from 'features/aiImages/AiImagesPage/usePromptOptimizer';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { useGetCurrentUsageByType } from 'features/usageIndicator/useGetCurrentUsageByType';
import { createContext, ReactNode, useContext, useState } from 'react';

const maxAmountOfImages = 4;

type ImageGeneratorReturnType = ReturnType<typeof useImageGenerator>;
type PromptOptimizerReturnType = ReturnType<typeof usePromptOptimizer>;

type AiImagesGeneratorContextValue = {
  prompt: string;
  setPrompt: PromptOptimizerReturnType['setPrompt'];
  setInitialPrompt: PromptOptimizerReturnType['setInitialPrompt'];
  amountImagesLeft: number;
  numberOfImages: number;
  setNumberOfImages: (value: number) => void;
  imageQuality: ImageQuality;
  setImageQuality: (value: ImageQuality) => void;
  resolution: ResolutionOption;
  setResolution: (key: ResolutionOption) => void;

  optimizePrompt: PromptOptimizerReturnType['optimizePrompt'];
  optimizedPrompt: PromptOptimizerReturnType['optimizedPrompt'];
  undoPromptOptimization: PromptOptimizerReturnType['undoPromptOptimization'];
  optimizeIsFetching: PromptOptimizerReturnType['isFetching'];

  generateImage: ImageGeneratorReturnType['generateImage'];
  generatedImages: ImageGeneratorReturnType['generatedImages'];
  isImagesFetching: ImageGeneratorReturnType['isFetching'];
  imageFetchingError: ImageGeneratorReturnType['error'];
};

const AiImagesGeneratorContext = createContext<AiImagesGeneratorContextValue | undefined>(
  undefined
);

export const useAiImagesGeneratorContext = () => {
  const context = useContext(AiImagesGeneratorContext);

  if (!context) {
    throw new Error(
      'AiImagesGeneratorContext was not provided. Please use <AiImagesGeneratorContextProvider>'
    );
  }

  return context;
};

type Props = {
  children: ReactNode;
};

export const resolutionOptionMap = {
  '1:1': { width: 1024, height: 1024, premium: false },
  '4:5': { width: 896, height: 1152, premium: true },
  '2:3': { width: 832, height: 1216, premium: true },
  '9:16': { width: 768, height: 1344, premium: true },
  '5:4': { width: 1152, height: 896, premium: true },
  '3:2': { width: 1216, height: 832, premium: true },
  '16:9': { width: 1344, height: 768, premium: true }
} as const;

export type ResolutionOption = keyof typeof resolutionOptionMap;
export const resolutionOptions = Object.keys(resolutionOptionMap) as ResolutionOption[];

export const imageQualityOptions = ['default', 'best', 'best_for_text'] as const;
export type ImageQuality = typeof imageQualityOptions[number];

export const AiImagesGeneratorContextProvider = ({ children }: Props) => {
  const [numberOfImages, setNumberOfImages] = useState(1);
  const [imageQuality, setImageQuality] = useState<ImageQuality>('default');
  const [resolution, setResolution] = useState<ResolutionOption>(resolutionOptions[0]);
  const {
    generateImage,
    generatedImages,
    isFetching: isImagesFetching,
    error: imageFetchingError
  } = useImageGenerator();
  const {
    prompt,
    setPrompt,
    setInitialPrompt,
    optimizePrompt,
    optimizedPrompt,
    undoPromptOptimization,
    isFetching: optimizeIsFetching
  } = usePromptOptimizer();
  const imageUsageResult = useGetCurrentUsageByType(UsageIndicatorType.aiImages);
  const amountImagesLeft = imageUsageResult?.data?.isUnlimited
    ? maxAmountOfImages
    : imageUsageResult.data?.available ?? maxAmountOfImages;

  const getSafeNumberOfImages = (value: number) => Math.max(1, Math.min(value, amountImagesLeft));

  const selectedNumberOfImages = getSafeNumberOfImages(numberOfImages);
  const handleNumberOfImagesChange = (value: number) => {
    setNumberOfImages(getSafeNumberOfImages(value));
  };

  return (
    <AiImagesGeneratorContext.Provider
      value={{
        prompt,
        setPrompt,
        setInitialPrompt,
        generateImage,
        generatedImages,
        isImagesFetching,
        imageFetchingError,
        optimizedPrompt,
        undoPromptOptimization,
        amountImagesLeft,
        numberOfImages: selectedNumberOfImages,
        setNumberOfImages: handleNumberOfImagesChange,
        optimizePrompt,
        resolution,
        setResolution,
        optimizeIsFetching,
        imageQuality,
        setImageQuality
      }}
    >
      {children}
    </AiImagesGeneratorContext.Provider>
  );
};
