import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import CountryFlag from 'components/base/CountryFlag';
import { formatDistanceToNow } from 'date-fns';
import { usePersonalityAuthor } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePersonalityAuthor';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { languageToDateFnsLocaleMapper } from 'features/language/languageToDateFnsLocaleMapper';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { useShowPersonalityCreationModal } from 'features/personality/creation/PersonalityCreationModal';
import { ChangeEvent, MouseEvent } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { httpDeletePersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpDeletePersonality';
import { invalidatePersonalitiesQuery } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalities';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

type BrandVoiceTableProps = {
  brandVoices: PersonalityDto[];
  page: number;
  limit: number;
  total: number;
  onChangePage: (_event: unknown | null, page: number) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function BrandVoiceTable({
  brandVoices,
  page,
  limit,
  total,
  onChangePage,
  onChangeRowsPerPage
}: BrandVoiceTableProps) {
  const getPersonalityAuthor = usePersonalityAuthor();

  const appLanguage = useAppSelector(getCurrentLanguage);

  const showPersonalityCreationModal = useShowPersonalityCreationModal();

  const customerId = useAppSelector(state => state.customer.id);
  const isOwnedByCustomer = (brandVoice: PersonalityDto) => {
    return brandVoice.customer_id === customerId;
  };

  const handleRowClick = (brandVoice: PersonalityDto) => {
    if (!isOwnedByCustomer(brandVoice)) {
      return;
    }

    showPersonalityCreationModal({
      initialViewMode: 'create_or_edit',
      personalityToEdit: brandVoice,
      isLanguageEditable: true,
      closeOnBack: true
    });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.brandVoice.table.name" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.brandVoice.table.owner" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.brandVoice.table.description" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.brandVoice.table.language" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.brandVoice.table.date" />
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {brandVoices.map(brandVoice => (
            <ClickableRow
              key={brandVoice.id}
              hover={isOwnedByCustomer(brandVoice)}
              $canBeHovered={isOwnedByCustomer(brandVoice)}
              onClick={() => handleRowClick(brandVoice)}
            >
              <TableCell>{brandVoice.label}</TableCell>
              <TableCell>{getPersonalityAuthor(brandVoice)}</TableCell>
              <TableCell>{brandVoice.description}</TableCell>
              <TableCell>
                <StyledFlag language={brandVoice.language} country={brandVoice.country} />
              </TableCell>
              <TableCell>
                {formatDistanceToNow(new Date(brandVoice.updated_at), {
                  addSuffix: true,
                  locale: languageToDateFnsLocaleMapper(appLanguage)
                })}
              </TableCell>
              <TableCell>
                <BrandVoiceMenu brandVoice={brandVoice} />
              </TableCell>
            </ClickableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        count={total}
        page={page}
        rowsPerPage={limit}
        onPageChange={onChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={onChangeRowsPerPage}
        component="div"
      />
    </TableContainer>
  );
}

const ClickableRow = styled(TableRow)<{ $canBeHovered: boolean }>`
  cursor: ${({ $canBeHovered }) => ($canBeHovered ? 'pointer' : 'default')};
`;

const StyledFlag = styled(CountryFlag)`
  width: 1.5rem;
  height: 1.125rem;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin-right: ${({ theme }) => theme.spacings.xsmall};
`;

type ProjectMenuProps = {
  brandVoice: PersonalityDto;
};

function BrandVoiceMenu({ brandVoice }: ProjectMenuProps) {
  const { anchorElement, isOpen, open, close } = useDropdown();

  const { mutate: deleteBrandVoice, isLoading: isDeleting } = useMutation({
    mutationFn: () => httpDeletePersonality.callEndpoint(brandVoice.id),
    onSuccess: () => invalidatePersonalitiesQuery()
  });

  const customerId = useAppSelector(state => state.customer.id);
  const isOwned = brandVoice.customer_id === customerId;

  const showPersonalityCreationModal = useShowPersonalityCreationModal();

  const handleOptionsClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    open(e);
  };

  const handleDeleteClick = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    deleteBrandVoice();
    close();
  };

  const handleEditClick = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    showPersonalityCreationModal({
      initialViewMode: 'create_or_edit',
      personalityToEdit: brandVoice,
      isLanguageEditable: true,
      closeOnBack: true
    });
    close();
  };

  const isLoading = isDeleting;

  const handleCloseMenu = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    close();
  };

  if (!isOwned) {
    return null;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <IconButton onClick={handleOptionsClick}>
        <MoreVertIcon />
      </IconButton>

      <StyledMenu anchorEl={anchorElement} open={isOpen} onClose={handleCloseMenu}>
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <FormattedMessage id="common.edit" />
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <FormattedMessage id="common.delete" />
        </MenuItem>
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    width: 13rem;
  }
`;
