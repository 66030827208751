// #tech-debt: https://app.clickup.com/t/38vnp8r

import { Clear } from '@mui/icons-material';
import { ReactComponent as WarningIcon } from 'assets/icon-warning.svg';
import FlexContainer from 'components/FlexContainer';
import { ReactElement, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components';

type Props = {
  children: ReactNode;
  color?: Color;
  onClose?: () => void;
  icon?: ReactNode;
};

type Color = keyof DefaultTheme['colors'];

export const WarningHint = ({
  children,
  color,
  onClose,
  icon = <StyledWarningIcon />
}: Props): ReactElement => (
  <Root direction="row" alignItems="flex-start" $color={color} gap="small">
    {icon}

    <div>{children}</div>

    {onClose && <Clear onClick={onClose} />}
  </Root>
);

const Root = styled(FlexContainer)<{ $color?: Color }>`
  padding: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.medium};

  background: ${({ theme, $color }) =>
    $color !== undefined ? theme.colors[$color] : theme.colors.warningYellow};

  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const StyledWarningIcon = styled(WarningIcon)`
  margin-right: ${({ theme }) => theme.spacings.small};
`;
