import { AddCircleRounded } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowModularWorkflowStepsModal } from 'features/modular-workflow/builder/ModularWorkflowStepsModal';
import { ApiModularWorkflowStepReference } from 'features/modular-workflow/builder/types-api';
import { ComponentProps, forwardRef } from 'react';

type Props = ComponentProps<typeof Button> & {
  onStepClick: (type: ApiModularWorkflowStepReference) => void;
};

export const AddStepBox = forwardRef<HTMLButtonElement, Props>(
  ({ onStepClick, ...rest }: Props, ref) => {
    const showWorkflowStepsModal = useShowModularWorkflowStepsModal({
      onStepClick
    });

    const handleButtonClick = () => {
      showWorkflowStepsModal();
    };

    return (
      <Button
        ref={ref}
        onClick={handleButtonClick}
        startIcon={<AddCircleRounded fontSize="small" />}
        {...rest}
      >
        <Typography>
          <FormattedMessage id="modular_workflow.builder.cta_add_step" />
        </Typography>
      </Button>
    );
  }
);
