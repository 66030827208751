import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useStoredPersonalityId } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { usePersonalityAuthor } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePersonalityAuthor';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ModalListCard } from 'features/theme-2024/modal-list-card/ModalListCard';
import { MouseEvent, useState } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';
import useToggle from 'utils/hooks/useToggle';

type PersonalityHandler = (personality: PersonalityDto) => void;
type ShareHandler = (personality: PersonalityDto, isTeamShared: boolean) => void;

export function PersonalityTile({
  personality,
  onDetails,
  onDelete,
  onClick,
  onShareClick
}: {
  personality: PersonalityDto;
  onDetails: PersonalityHandler;
  onDelete: PersonalityHandler;
  onClick: PersonalityHandler;
  onShareClick: ShareHandler;
}) {
  const customerId = useAppSelector(state => state.customer.id);
  const [isDeleting, setIsDeleting] = useState<number | null>(null);
  const currentPersonalityId = useStoredPersonalityId();
  const getPersonalityAuthor = usePersonalityAuthor();

  const { anchorElement, isOpen, open, close } = useDropdown();

  const [isTeamShared, toggleTeamSharing] = useToggle(personality.sharing_permission === 'team');

  const createDetailsClickHandler =
    (personality: PersonalityDto) => (e: MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();

      onDetails(personality);
    };

  const createDeleteClickHandler =
    (personality: PersonalityDto) => (e: MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();
      setIsDeleting(personality.id);
      onDelete(personality);
    };

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    anchorElement ? close() : open(e);
  };

  const handleTeamShareClick = (personality: PersonalityDto) => (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    onShareClick(personality, isTeamShared);
  };

  let description = personality.description;
  if (!description && personality.customer_id === customerId) {
    description = personality.definition;
  }

  return (
    <>
      <ModalListCard
        {...withGtmInteraction(gtmIds.personalitiesLibrary.selectPersonality)}
        onClick={() => onClick(personality)}
        isActive={currentPersonalityId === personality.id}
        title={personality.label}
        subtitle={
          <FormattedMessage
            id="aiWriter.inspirations.chat.personalities.library_modal.personality.author"
            values={{
              source: getPersonalityAuthor(personality)
            }}
          />
        }
        titleActions={
          customerId === personality.customer_id && (
            <IconContainer onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconContainer>
          )
        }
      >
        {isDeleting === personality.id ? (
          <DeletionBox>
            <CircularProgress size={32} />
          </DeletionBox>
        ) : (
          description && <DefinitionText>{description}</DefinitionText>
        )}
      </ModalListCard>

      <Menu open={isOpen} anchorEl={anchorElement} onClose={close}>
        <MenuItem onClick={createDetailsClickHandler(personality)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="common.edit" />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={createDeleteClickHandler(personality)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="common.delete" />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleTeamShareClick(personality)}>
          <ListItemIcon>
            <Switch
              size="small"
              color="primary"
              checked={isTeamShared}
              onChange={toggleTeamSharing}
            />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.list_view.team_share" />
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

const IconContainer = styled(IconButton)``;

const DefinitionText = styled(Typography).attrs({ variant: 'body2' })`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const DeletionBox = styled.div`
  margin: auto;
`;
