import { useQuery } from '@tanstack/react-query';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { useAppSelector } from 'store/hooks';

export const useDefaultPersonalityQuery = () => {
    const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
      getCurrentModelLanguageAndCountry
    );
  
    const params = { country, language };
    return useQuery({
      queryKey: httpGetDefaultPersonality.makeQueryKey(params),
      queryFn: () => httpGetDefaultPersonality.callEndpoint(params)
    });
  }