import { Typography } from '@mui/material';
import { PersonalityTile } from 'features/aiWriter/AiWriterSidebar/steps/chat/PersonalityTile';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ModalListCardGrid } from 'features/theme-2024/modal-list-card/ModalListCardGrid';
import { ComponentProps } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';

type Props = {
  personalities: PersonalityDto[];
  onTileClick: ComponentProps<typeof PersonalityTile>['onClick'];
  onDetailsClick: ComponentProps<typeof PersonalityTile>['onDetails'];
  onDeleteClick: ComponentProps<typeof PersonalityTile>['onDelete'];
  onShareClick: ComponentProps<typeof PersonalityTile>['onShareClick'];
};

export function PersonalityTiles({
  personalities,
  onTileClick,
  onDetailsClick,
  onDeleteClick,
  onShareClick
}: Props) {
  if (personalities.length === 0) {
    return (
      <Typography textAlign="center" variant="body2">
        <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.empty" />
      </Typography>
    );
  }

  return (
    <ModalListCardGrid>
      {personalities.map(personality => (
        <PersonalityTile
          key={personality.id}
          personality={personality}
          onDetails={onDetailsClick}
          onDelete={onDeleteClick}
          onClick={onTileClick}
          onShareClick={onShareClick}
        />
      ))}
    </ModalListCardGrid>
  );
}
